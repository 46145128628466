<template>
  <div>
    <div class="flex ju-between" style="width:100%">
      <!-- 小车 -->
      <div class="leftBox">
        <div class="title">
          <div class="imgBox flex ju-center al-center"><img src="@/assets/images/order/u6.png" alt=""></div>待发运小车
        </div>
        <ly-searchList :iptData="waitIptData" @searchAdd="searchSmall" @resetBtn="resetBtn">
        </ly-searchList>
        <ly-table :scroll="{ x: 200}" :rowSelectionShow="true" rowKey="orderVehicleId" ref="tabelRef" @onChange="onChange" :columns="waitColumns" size="small" :data="waitData" :pagination="pagination" @pageChange="pageChange" :loading="loading">
          <template #vinNo="{ record }">
            <a @click="smallCar(record)">
              <div class="flex al-center ju-center">
                <div class="isExpatriate" v-if="record.orderSource.value === 9">
                  华月
                </div>
                {{ record.vinNo }}
              </div>
              <span>{{ record.brand }}{{ record.model }}</span>
            </a>
            <div class="color-R fz-12" v-if="record.orderSource?.value === 9">{{ record.taskName }}</div>
          </template>
          <template #vehicleTransStatus="{ record} ">
            <span :style="{color:colorList[record.vehicleTransStatus?.value]}">{{ record.vehicleTransStatus?.label }}</span>
            <div class="fz-12" v-if="record.vehicleTransStatus.value === 4">{{ record.transit.cityName }}</div>
          </template>
          <template #transportCarNo="{ record }">
            <a @click="vehBigAdd(record)">{{ record.transportCarNo }}</a>
          </template>
          <template #startAddress="{ record }">
            <div> {{   record.startAddress }}</div>{{ record.endAddress}}
          </template>
          <template #nowStartAddress="{ record }">
            <div> {{   record.nowStartAddress }}</div>{{ record.nowEndAddress}}
          </template>
        </ly-table>
      </div>
      <!-- 大车 -->
      <div class="leftBox">
        <div class="title">
          <div class="imgBox flex ju-center al-center"><img src="@/assets/images/order/u9.png" alt=""></div>
          <div v-for="item in toolTypeData" :key="item.id" class=" cur-p item" @click="changeToolType(item)" :class="toolType === item.type?'changeBack':''">
            <span>{{ item.name }}</span>
          </div>
        </div>
        <template v-if="toolType !== 3">
          <ly-searchList :iptData="alreadyIptData" @searchAdd="searchBig" @resetBtn="resetBtnAlready">
          </ly-searchList>
          <ly-table rowKey="carrierTruckId" :scroll="{ x: 200}" :columns="alreayColumns" ref="tableRef" size="small" :data="alreayData" :pagination="paginationBig" @pageChange="pageChangeBig" :loading="loadingBig">
            <template #carNo="{ record }">
              <div class="flex al-center ju-center">
                <div class="isExpatriate" v-if="record.orderSource?.value === 9">
                  华月
                </div>
                <span v-if="record.truckStatus?.value === 1">{{ record.carNo }}</span>
                <a v-else @click="vehBigAdd(record,1)">{{ record.carNo }}</a>
              </div>
            </template>
            <template #carrierName="{ record }">
              {{ record.carrierName }} <a @click="newAddOpen(record)" style="margin-left:4px">新增</a>
            </template>
            <template #truckStatus="{ record} ">
              <span :style="{color:colorList[record.truckStatus?.value]}">{{ record.truckStatus?.label }}</span>
            </template>
            <template #truckType="{ record }">
              {{ record.truckType?.label }}
            </template>
            <template #startAddress="{ record }">
              <div> {{   record.startAddress }}</div>{{ record.endAddress}}
            </template>
            <template #add="{ record }">
              <a @click="loadAdd(record)">装车</a>
              <a class="m-l1" @click="startAdd(record)" v-if="record.truckStatus?.value === 2">发车</a>
              <a class="m-l1" @click="arriveAdd(record)" v-if="record.truckStatus?.value === 3">到达</a>
            </template>
          </ly-table>
        </template>
        <template v-if="toolType === 3">
          <ly-searchList :iptData="gpsIptData" @searchAdd="searchBig" @resetBtn="resetBtnAlready">
          </ly-searchList>
          <ly-table rowKey="carrierTruckId" :scroll="{ x: 200}" :columns="gpsColumns" ref="tableRef" size="small" :data="gpsData"  :loading="loadingBig">
            <template #carNo="{ record }">
              <div class="flex al-center ju-center">
                <div class="isExpatriate" v-if="record.orderSource?.value === 9">
                  华月
                </div>
                <span v-if="record.truckStatus?.value === 1">{{ record.carNo }}</span>
                <a v-else @click="vehBigAdd(record,1)">{{ record.carNo }}</a>
              </div>
            </template>
            <template #truckStatus="{ record} ">
              <span :style="{color:colorList[record.truckStatus?.value]}">{{ record.truckStatus?.label }}</span>
            </template>
            <template #truckType="{ record }">
              {{ record.truckType?.label }}
            </template>
            <template #startAddress="{ record }">
              <div> {{   record.startAddress }}</div>{{ record.endAddress}}
            </template>
            <template #add="{ record }">
              <a class="m-l1" @click="arriveAdd(record)" >新增位置</a>
            </template>
          </ly-table>
        </template>
      </div>
    </div>
    <!-- 小车信息 -->
    <SmallVehicle v-model:smallVisible="smallVisible" @success="complete" v-if="smallVisible" :orderVehicleId="orderVehicleId"></SmallVehicle>
    <!-- 大车装车 -->
    <LoadCar v-model:loadVisible="loadVisible" @loadSuccess="loadSuccess" :toolType="toolType" v-if="loadVisible" :selectedID="selectedID" :title="loadTitle"></LoadCar>
    <!-- 详情 -->
    <VehBigDetails @complete="complete" :title="vehBigInfo.title" :CarID="driver.transportId" v-model:visible="VehBigVisible" v-if="VehBigVisible"></VehBigDetails>
    <!-- 提示 -->
    <ly-modal v-model:visible="delVisible" title="系统提示" delText="确定" @handleOk="handleOk">
      <p>确认要装车吗？</p>
    </ly-modal>
    <!-- 第二次装车 -->
    <ly-modal v-model:visible="secondaryVisible" title="装车" delText="确定" @handleOk="secondaryOk" :loading="btnLoading">
      <div>
        <div class="flex al-center">
          <span class="m-r1" style="width:80px;text-align: right;"><span class="requder">*</span>起运地</span>
          <a-cascader style="width:100%;" show-search :change-on-select="true" v-model:value="secondary.startAddress" :options="$store.state.app.cityDict" placeholder="起运地" />
        </div>
        <div class="flex al-center m-t2 m-b2">
          <span class="m-r1" style="width:80px;text-align: right;"><span class="requder">*</span>目的地</span>
          <a-cascader style="width:100%" show-search :change-on-select="true" v-model:value="secondary.endAddress" :options="$store.state.app.cityDict" placeholder="起运地" />
        </div>
        <div class="flex al-center m-b2">
          <span class="m-r1" style="width:80px;text-align: right;"><span class="requder">*</span>单台运费</span>
          <a-input-number style="width:100%" v-model:value="secondary.freight" :min="0" size="large">
          </a-input-number>
        </div>
      </div>
    </ly-modal>
    <!-- 第三次补位 -->
    <ly-modal v-model:visible="middleVisible" title="中途补位" delText="确定" @handleOk="middleOk" :loading="btnLoading">
      <div>
        <div class="flex al-center">
          <span class="m-r1" style="width:70px;text-align: right;"><span class="requder">*</span>起运地</span>
          <a-cascader style="width:100%;" show-search :change-on-select="true" v-model:value="mindLoading.startAddress" :options="$store.state.app.cityDict" placeholder="起运地" />
        </div>
        <div class="flex al-center m-t2 m-b2">
          <span class="m-r1" style="width:70px;text-align: right;"><span class="requder">*</span>目的地</span>
          <a-cascader style="width:100%" show-search :change-on-select="true" v-model:value="mindLoading.endAddress" :options="$store.state.app.cityDict" placeholder="起运地" />
        </div>
        <div class="flex al-center">
          <span class="m-r1" style="width:70px;text-align: right;">油卡</span>
          <a-input-number style="width:100%" v-model:value="mindLoading.oilCard" :min="0" size="large">
          </a-input-number>
        </div>
        <div class="flex al-center m-t2 m-b2">
          <span class="m-r1" style="width:70px;text-align: right;">转账</span>
          <a-input-number style="width:100%" v-model:value="mindLoading.bankCard" :min="0" size="large">
          </a-input-number>
        </div>
      </div>
    </ly-modal>
    <!-- 发车 -->
    <ly-modal v-model:visible="startVisible" title="填写收款方式" @handleOk="driverOk" :loading="btnLoading">
      <div class="money">
        总金额<span class="m-l1">{{ totalAmount }}</span>
      </div>
      <a-input addon-before="油卡" v-model:value="driver.oilCard" :min="0" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部油卡">
            <EnterOutlined @click="driver.oilCard = totalAmount, driver.bankCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
      <div class="m-t2 m-b2">
        <a-input addon-before="转账" v-model:value="driver.bankCard" :min="0" type='number' size="large">
          <template #suffix>
            <a-tooltip title="全部转账">
              <EnterOutlined @click="driver.bankCard = totalAmount, driver.oilCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </template>
        </a-input>
      </div>
    </ly-modal>

    <!-- 新增车辆 -->
    <CrmVehicleAdd ref="crmVehicleAddRef" @success="success" :id="customerId" />
    <!-- 新增司机 -->
    <CrmDriverAdd ref="crmDriverAddRef" @success="success" :id="customerId" />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { EnterOutlined } from '@ant-design/icons-vue'
import { waitIptData, alreadyIptData, waitColumns, alreayColumns, gpsColumns, gpsIptData } from './vehicle'
import { transportPage, operationShip, transportDepart, transportLoadUp, transportmind, transitList } from '@/api/transport/vehicle'
import SmallVehicle from './components/smallVehicle.vue';
import LoadCar from './components/loadCar.vue';
import VehBigDetails from './components/vehBigDetails.vue'
import { message } from 'ant-design-vue';
import { compute, addressTran, findItem } from '@/utils/util'
import { cloneDeep } from 'lodash-es';
import CrmVehicleAdd from '@/views/components/crmVehicleAdd'
import CrmDriverAdd from '@/views/components/crmDriverAdd'

const gpsData = ref([])
const customerId = ref('')
const crmVehicleAddRef = ref(null)
const crmDriverAddRef = ref(null)
const truckStatus = ref(0)
const tabelRef = ref(null)
const smallVisible = ref(false)
const loadVisible = ref(false)
const waitData = ref([])
const alreayData = ref([])
const orderVehicleId = ref('')
const loadTitle = ref('')
const selectedVehicles = ref([])
const delVisible = ref(false)
const middleVisible = ref(false)
const startVisible = ref(false)
const VehBigVisible = ref(false)
const totalAmount = ref(0)
const searchMsg = ref({})
const searchMsgBig = ref({})
const loading = ref(false)
const loadingBig = ref(false)
const secondaryVisible = ref(false)
const toolType = ref(1)
const btnLoading = ref(false)
const toolTypeData = ref([
  {
    name: '板车发运',
    type: 1
  },
  {
    name: '司机发运',
    type: 2
  },
  {
    name: '在途跟踪',
    type: 3
  },
])
const colorList = ref({
  4: '#d3820e',
  1: '#d3820e',
  5: '#d3820e',
  3: '#396afc'
})

const selectedID = ref({
  carrierId: '',
  orderVehicleIds: [],
  carrierTruckId: '',
  carrierDriverId: ''
})
const mindLoading = reactive({
  startAddress: [],
  endAddress: [],
  oilCard: 0,
  bankCard: 0,
  transportId: ''
})
const driver = reactive({
  oilCard: 0,
  bankCard: 0,
  transportId: ''
})
const secondary = reactive({
  startAddress: [],
  endAddress: [],
})
const vehBigInfo = reactive({
  title: ''
})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const paginationBig = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})


//新增操作
const newAddOpen = (record) => {
  customerId.value = record.customerId
  if (toolType.value === 1) {
    crmVehicleAddRef.value.setData()
  } else {
    crmDriverAddRef.value.setData()
  }

}
const success = () => {
  reqOperationShip()
}

const changeToolType = (item) => {
  if (item.type == 1) {
    alreayColumns.value[0].title = '大车车牌号'
    alreadyIptData.value[0].placeholder = '大车车牌号'
  } else {
    alreayColumns.value[0].title = '司机姓名'
    alreadyIptData.value[0].placeholder = '司机姓名'
  }
  toolType.value = item.type
  reqOperationShip()

}
// 大车详情
const vehBigAdd = (record, type) => {
  vehBigInfo.title = record.carNo
  driver.transportId = type ? record.id : record.transportId
  VehBigVisible.value = true
}
//小车搜索
const searchSmall = (e) => {
  pagination.value.current = 1
  searchMsg.value = cloneDeep(e)
  searchMsg.value.startAddress = addressTran(searchMsg.value.startAddress)
  searchMsg.value.endAddress = addressTran(searchMsg.value.endAddress)
  reqTransportPage()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqTransportPage()
}
const resetBtnAlready = () => {
  paginationBig.value.current = 1
  searchMsgBig.value = {}
  reqOperationShip()
}
// 大车搜索
const searchBig = (e) => {
  paginationBig.value.current = 1
  searchMsgBig.value = cloneDeep(e)
  searchMsgBig.value.startAddress = addressTran(searchMsgBig.value.startAddress)
  searchMsgBig.value.endAddress = addressTran(searchMsgBig.value.endAddress)
  reqOperationShip()
}
//发车
const driverOk = () => {
  console.log(driver);
  if (compute('+', driver.oilCard, driver.bankCard).result !== Number(totalAmount.value)) {
    message.error('金额数目填写错误,请重新填写')
    return
  }
  btnLoading.value = true
  transportDepart(driver).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    reqTransportPage()
    reqOperationShip()
    startVisible.value = false
  }).finally(() => {
    btnLoading.value = false
  })
}
const startAdd = (record) => {
  for (let key in driver) {
    driver[key] = 0
  }
  totalAmount.value = record.freightCost
  driver.transportId = record.id
  startVisible.value = true
}
// 中途确认装车
const middleOk = () => {
  let msg = cloneDeep(mindLoading)
  if (!isAddressNull(msg)) return
  if ((msg.bankCard + msg.oilCard) === 0) {
    message.error('油卡和转账总和必须大于0')
    return
  }
  let list = isLoadingFun()
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  msg.orderVehicleIds = list
  btnLoading.value = true
  transportLoadUp(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    middleVisible.value = false
    loadSuccess()
  }).finally(() => {
    btnLoading.value = false
  })
}
//提示确认
const handleOk = () => {
  if (truckStatus.value === 2) {
    for (let key in secondary) {
      secondary[key] = 0
    }
    delVisible.value = false
    secondaryVisible.value = true
  } else {
    delVisible.value = false
    middleVisible.value = true
  }
}
// 装车成功
const loadSuccess = () => {
  tabelRef.value.selectedRowKeys = []
  selectedVehicles.value = []
  reqTransportPage()
  reqOperationShip()
}
// 到达
const arriveAdd = (record) => {
  vehBigInfo.title = record.carNo
  driver.transportId = record.id
  VehBigVisible.value = true
}
//小车勾选
const onChange = (e, v) => {
  selectedVehicles.value = v
}
//装车
const loadAdd = (item) => {
  if (!isLoadingFun()) return
  let list = isLoadingFun()
  selectedID.value.orderVehicleIds = list
  selectedID.value.carrierId = item.customerId
  selectedID.value.carrierTruckId = item.carrierTruckId
  selectedID.value.carrierDriverId = item.carrierDriverId
  if (item.truckStatus.value === 1) {
    loadTitle.value = item.carrierName
    loadVisible.value = true
  } else {
    for (let key in mindLoading) {
      mindLoading[key] = 0
    }
    truckStatus.value = item.truckStatus.value
    mindLoading.transportId = item.id
    delVisible.value = true
  }
}
// 待发运装车状态
const secondaryOk = () => {
  if (!isAddressNull(secondary)) return
  if (!secondary.freight) {
    message.warning('请填写单台运费')
    return
  }
  let list = isLoadingFun()
  btnLoading.value = true
  transportmind({
    orderVehicleIds: list,
    transportId: mindLoading.transportId,
    startAddress: addressTran(secondary.startAddress),
    endAddress: addressTran(secondary.endAddress),
    freight: secondary.freight
  }).then(res => {
    if (res.code !== 10000) return
    secondaryVisible.value = false
    message.success(res.msg)
    loadSuccess()
  }).finally(() => {
    btnLoading.value = false
  })
}
const pageChange = (e) => {
  pagination.value = e
  reqTransportPage()
}
const pageChangeBig = (e) => {
  paginationBig.value = e
  reqOperationShip()
}
const smallCar = (record) => {
  orderVehicleId.value = record.orderVehicleId
  smallVisible.value = true
}
const reqTransportPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize,
  }
  Object.assign(msg, searchMsg.value)
  transportPage(msg).then(res => {
    if (res.code !== 10000) return
    waitData.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const reqOperationShip = () => {
  loadingBig.value = true
  let msg = {
    current: paginationBig.value.current,
    size: paginationBig.value.pageSize,
    toolType: toolType.value
  }
  Object.assign(msg, searchMsgBig.value)
  if (toolType.value === 3) {
    transitList(msg).then(res => {
      if (res.code !== 10000) return
    gpsData.value = res.data
    }).finally(() => {
      loadingBig.value = false
    })
    return
  }
  operationShip(msg).then(res => {
    if (res.code !== 10000) return
    alreayData.value = res.data.records
    paginationBig.value.total = res.data.total
  }).finally(() => {
    loadingBig.value = false
  })
}
//完成
const complete = () => {
  reqTransportPage()
  reqOperationShip()
}
const isLoadingFun = () => {
  if (selectedVehicles.value.length < 1) {
    message.warn('请先选需要发运的小车')
    return
  }
  if (findItem(selectedVehicles.value, [1, 4, 5], 'vehicleTransStatus', 'value')) {
    message.warn('所选择的订单中有已安排的车辆，请检查重新选择')
    return false
  } else {
    let list = []
    selectedVehicles.value.forEach(opt => {
      list.push(opt.orderVehicleId)
    })
    return list
  }
}
const isAddressNull = (secondary) => {
  if (!secondary.startAddress || secondary.startAddress.length === 0) {
    message.warning('请选择起运地')
    return false
  } else if (secondary.startAddress.length === 1) {
    message.warning('起运地必须选择到市')
    return false
  }
  if (!secondary.endAddress || secondary.endAddress.length === 0) {
    message.warning('请选择目的地')
    return false
  } else if (secondary.endAddress.length === 1) {
    message.warning('目的地必须选择到市')
    return false
  }
  return true
}

onMounted(() => {
  reqTransportPage()
  reqOperationShip()
})
</script>

<style lang="less" scoped>
.leftBox {
  width: 49.5%;
  min-height: 750px;
  background: #fff;
  padding: 15px 10px;
}
.title {
  color: #555555;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  .imgBox {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    background: #f2f2f2;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.smallNav {
  height: 100px;
  background: red;
}
.requder {
  color: red;
}

.money {
  font-size: 24px;
  margin-bottom: 10px;
  span {
    color: red;
  }
}
.item {
  background: #f2f2f2;
  padding: 2px 18px;
  color: #6b778c;
}
.changeBack {
  background: #0066cc;
  color: #fff;
}
.isExpatriate {
  min-width: 20%;
  height: 16px;
  background-color: #001529;
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
</style>